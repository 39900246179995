import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../models/usuario.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private url = "https://api2.buscomida.mx/vendedor/usuarios";
  constructor(private http: HttpClient) { }
  crear (frmObj:Usuario):Promise<number>{
    let req = this.http.post<{Resultado:number}>(this.url, frmObj, { observe: 'response' });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }

  recuperar (usuario:string):Promise<number>{
    let frmObj = {Usuario:usuario}
    let req = this.http.patch<{Resultado:number}>(this.url, frmObj, { observe: 'response' });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }
}
