
<header *ngIf="IdUsuario ==0">
  <nav class="nav1">
      <section class="contenedor nav">
          <div class="logo">
              <img src="assets/img/BuscOmida1.png" alt="">
          </div>
          <div class="enlaces-header">
            <a routerLink="/"><i class="fa-solid fa-house"></i>&nbsp;Inicio</a>
              <a routerLink="/registro">Registro</a>
              <a routerLink="/login">Iniciar sesión</a>

          </div>
          <div class="hamburguer">
              <i class="fa-solid fa-bars"></i>
          </div>
      </section>
  </nav>
</header>
<!----
<header *ngIf="IdUsuario >0">
  <nav>
      <section class="contenedor nav">
          <div class="logo">
              <img src="assets/img/BuscOmida1.png" alt="">
          </div>
          <div class="enlaces-header">
              <a routerLink="/">Inicio</a>
          
              <a routerLink="/registroProductos">Productos</a>
              <a href=""><i class="fa-solid fa-circle-user"></i> Perfil</a>
              <a href="" (click)="cerrar()">Cerrar sesion</a>
          </div>
          <div class="hamburguer">
              <i class="fa-solid fa-bars"></i>
          </div>
      </section>
  </nav>
</header>
--->


<header *ngIf="IdUsuario >0">
<div class="wrapper">
<nav>
  
  <div class="content">
    <div class="logo">
      <img src="assets/img/BuscOmida1.png" alt="">
  </div>
    <ul class="links">
      <li><a routerLink="/"><i class="fa-solid fa-house"></i>&nbsp;Inicio</a></li>
      <li>
        <a href="#" class="desktop-link"><i class="fa-brands fa-product-hunt"></i>&nbsp;Productos</a>
        <input type="checkbox" id="show-features">
        <label for="show-features">Features</label>
        <ul>
          <li><a routerLink="/registroProductos">Registrar productos</a></li>
          <li><a routerLink="/listaProductos">Ver productos</a></li>   
        </ul>
      </li>
      <li>
        <a href="#" class="desktop-link">Services</a>
        <input type="checkbox" id="show-services">
        <label for="show-services">Services</label>
        <ul>
          <li><a href="#">Drop Menu 1</a></li>
          <li><a href="#">Drop Menu 2</a></li>
          <li><a href="#">Drop Menu 3</a></li>
          <li>
            <a href="#" class="desktop-link">More Items</a>
            <input type="checkbox" id="show-items">
            <label for="show-items">More Items</label>
            <ul>
              <li><a href="#">Sub Menu 1</a></li>
              <li><a href="#">Sub Menu 2</a></li>
              <li><a href="#">Sub Menu 3</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li> <a href=""><i class="fa-solid fa-circle-user"></i>&nbsp;Perfil</a></li>
      <li><a href="" (click)="cerrar()">Cerrar sesion</a></li>
    </ul>
  </div>
  
</nav>
</div>
</header>
