import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Producto } from 'src/app/models/producto.model';
import { ProductoService } from 'src/app/services/producto.service';

@Component({
  selector: 'app-list-prodcuts',
  templateUrl: './list-prodcuts.component.html',
  styleUrls: ['./list-prodcuts.component.css']
})
export class ListProdcutsComponent implements OnInit {
  @Input() IdUsuario :number =0;
  public page!:number;
  constructor(private productService:ProductoService, private router: Router, private toastr: ToastrService) { }

  listProductos:Array<Producto> = new Array();
  ngOnInit(): void {
    let idUsr = localStorage.getItem("IdUsuario");
    //console.warn("Lodaing data for user:",idUsr);
    if (idUsr == null)
      return;
    
    this.IdUsuario = parseInt(idUsr);
    this.obtenerProductos();
  }

  
  obtenerProductos(){
    this.productService.lista(this.IdUsuario).then((lista)=>{
      if (lista != null)
        this.listProductos = lista;
      //console.warn("data downloaded:", this.listProductos);
    });
  }

  eliminar(id:number){
    let producto = new Producto();
    producto.IdUsuario = this.IdUsuario;
    producto.Id = id;
    console.warn("Eliminar", producto);
    let s = this.productService.eliminar(producto);
    s.then(resultado =>{
      //console.warn("Resultado:", resultado);
      if (resultado >0){
        this.toastr.success('Producto eliminado', 'Eliminar');
        //setTimeout(()=>window.location.reload(), 1000);
        this.obtenerProductos();
      }
      else{
        this.toastr.error('¡Ups algo salio mal!', 'Error')
      }
    });
  }

  cambiarEstado(prod:Producto){
    this.productService.cabiarEstado(prod).then(resultado=>{
      if (resultado >0){
        this.toastr.info('Producto disponible', 'Estado');
        //setTimeout(()=>window.location.reload(), 1000);
        //this.obtenerProductos();
        
        prod.EnUso = !prod.EnUso;
      }
      else if(resultado <0){
        this.toastr.error('¡Ups algo salio mal!', 'Error')
      }
    })
  }
}
