<p>password-recovery works!</p>
<app-header></app-header>
<section class="container">
    <div class="form login">
        <div class="form-content">
            <p>Recuperar contraseña</p>
            <form action="#">
                <div class="field input-field">
                    <input type="text" name="formObj" [(ngModel)]="formObj" placeholder="Usuario" class="input">
                </div>
                <div class="field button-field">
                    <button (click)="recuperar()">Enviar</button>
                </div>
            </form>
            <div class="form-link">
                <span>¿Ya tienes una cuenta? <a [routerLink]="['/login']" routerLinkActive="active"
                        class="link signup-link">clic aquí</a></span>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>