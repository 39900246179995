import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { Producto } from '../models/producto.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  private url = "https://api2.buscomida.mx/vendedor/productos";
  constructor(private http: HttpClient) { }

  lista(frmObj:number):Promise<Array<Producto>|null>{
    let req = this.http.get<{Datos:Array<Producto>}>(this.url+"?IdUsuario="+frmObj, { observe: 'response' });

    return new Promise<Array<Producto>|null>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve([]);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Datos :null);
      });
    });
  }

  crear(frmObj:Producto):Promise<number>{
    const formData: FormData = new FormData();
    let b = <Blob>frmObj.Archivo;
    formData.append("Archivo", b);
    formData.append("Nombre", frmObj.Nombre);
    formData.append("Descripcion", frmObj.Descripcion);
    formData.append("Categoria", frmObj.Categoria);
    formData.append("Precio", ""+frmObj.Precio);
    formData.append("IdUsuario", ""+frmObj.IdUsuario);
    let req = this.http.post<{Resultado:number}>(this.url, formData, { observe: 'response' });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }

  eliminar(frmObj:Producto):Promise<number>{
    let req = this.http.delete<{Resultado:number}>(this.url, { body:frmObj, observe: 'response' });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }

  cabiarEstado(frmObj:Producto):Promise<number>{
    let params = {Id:frmObj.Id, Estado: !frmObj.EnUso};
    let req = this.http.patch<{Resultado:number}>(this.url, params, { observe: 'response' });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }
}
