import { Component, Input, OnInit } from '@angular/core';
import { Producto } from 'src/app/models/producto.model';
import { ProductoService } from 'src/app/services/producto.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-frm-products',
  templateUrl: './frm-products.component.html',
  styleUrls: ['./frm-products.component.css']
})
export class FrmProductsComponent implements OnInit {
  @Input() formObj: Producto = new Producto();
  

  constructor(private formService:ProductoService, private router:Router, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  archivoSeleccionado(evT:EventTarget|null){
    let input = <HTMLInputElement>evT;
    let archivos = input.files;

   this.formObj.Archivo = (archivos != null && archivos.length >0) ? archivos[0] :null;

   console.log("Archivo seleccionado", this.formObj.Archivo);
  }

  crear() {
    let idUsr = localStorage.getItem("IdUsuario");
    if (idUsr == null)
      return;
    
      this.formObj.IdUsuario = parseInt(idUsr);
    console.info("Form object", this.formObj);
    let s =this.formService.crear(this.formObj);
    s.then(resultado =>{
      console.warn("Resultado:", resultado);
      if (resultado >0){
        //localStorage.setItem("IdUsuario", ""+resultado);//this.cookieService.set('IdUsuario', ""+resultado);
        this.router.navigate(['/listaProductos']);
        this.toastr.success('Producto registrado con exito', 'Producto registrado');
        
        //this.router.navigate(['/inicio']);
      }
    });
  }


}
