<app-header></app-header>
<div class="container">
    <div class="form login">
        <div class="form-content">
            <p>Iniciar sesión</p>
            <form action="#" autocomplete="off">
                <div class="field input-field">
                    <input type="text" name="formObj.Usuario" [(ngModel)]="formObj.Usuario" 
                        class="input" required="required">
                        <label>Usuario</label>
                </div>
                <div class="error" *ngIf="campoNoValido('usuario')">
                    Campo requerido
                </div>
                <div class="field input-field">
                    <input type="password" name="formObj.Contrasenia" [(ngModel)]="formObj.Contrasenia"
                      class="password" required="required">
                        <label>Contraseña</label>
                </div>
                <div class="error" *ngIf="campoNoValido('password')">
                    Campo requerido 
                </div>
                <div class="form-link">
                    <a [routerLink]="['/recuperarContrasena']" routerLinkActive="active" class="link signup-link">¿Se te
                        olvidó tu contraseña?</a>
                </div>
                <div class="field button-field">
                    <button (click)="crear()">Iniciar sesión</button>
                </div>
            </form>
            <div class="form-link">
                <span>¿No tienes una cuenta? <a [routerLink]="['/registro']" routerLinkActive="active"
                        class="link signup-link">clic aquí</a></span>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>