<app-header></app-header>
<div class="container">
    <div class="form signup">
        <div class="form-content">
            <p>Registro</p>
            <form action="#" autocomplete="off" [formGroup]="Formulario" (ngSubmit)="submitformulario()">
                <div class="field input-field">
                    <input type="text" name="formObj.Nombre" [(ngModel)]="formObj.Nombre" class="input" required
                        formControlName="nombre" />
                    <label for="">Nombre</label>
                </div>
                <div class="error" *ngIf="campoNoValido('nombre')">
                    Campo requerido [Nombre]
                </div>
                <div class="field input-field">
                    <input type="text" name="formObj.Apellidos" [(ngModel)]="formObj.Apellidos" class="input" required
                        formControlName="apellidos" />
                    <label for="">Apellidos</label>
                </div>
                <div class="error" *ngIf="campoNoValido('apellidos')">
                    Campo requerido [Apellido materno + Apellido paterno]
                </div>
                <div class="field input-field">
                    <input type="tel" name="formObj.Telefono" [(ngModel)]="formObj.Telefono" class="input" required
                        formControlName="telefono" />
                    <label for="">Telefono</label>
                </div>
                <div class="error" *ngIf="campoNoValido('telefono')">
                    Campo requerido [10 dijitos]
                </div>
                <div class="field input-field">
                    <input type="text" name="formObj.Usuario" [(ngModel)]="formObj.Usuario" class="input" required
                        formControlName="usuario" />
                    <label for="">Usuario</label>
                </div>
                <div class="error" *ngIf="campoNoValido('usuario')">
                    Campo requerido
                </div>
                <div class="field input-field">
                    <input type="Password" name="formObj.Contrasenia" [(ngModel)]="formObj.Contrasenia" class="password"
                        required formControlName="password" />
                    <label for="">Contraseña</label>
                </div>
                <div class="error" *ngIf="campoNoValido('password')">
                    Campo requerido Min 8 caracteres
                </div>
                <div class="field input-field">
                    <input type="email" name="formObj.email" [(ngModel)]="formObj.Correo" class="input"
                        required formControlName="correo" />
                    <label for="">Correo</label>
                </div>
                <div class="error" *ngIf="campoNoValido('correo')">
                    Ingresa Formato de correo valido
                </div>
                <div class="field button-field">
                    <button type="submit">Registar</button>
                </div>
            </form>

            <div class="form-link">
                <span>¿Ya tienes una cuenta?
                    <a [routerLink]="['/login']" routerLinkActive="active" class="link login-link">Iniciar
                        sesión</a></span>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>