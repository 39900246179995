<app-header></app-header>
<div class="container">
  <table class="table">
      <caption>Lista de productos</caption>
      <thead>
          <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th>Categoria</th>
              <th>Precio</th>
              <th>Descripción</th>
              <th>Imagen</th>
              <th>Disponible</th>
              <th>Opciones</th>
          </tr>
      </thead>
      <tbody>
        <tr *ngFor="let producto of listProductos | paginate: { itemsPerPage: 5, currentPage: page }">
          <td>{{producto.Id}}</td>
          <td>{{producto.Nombre}}</td>
          <td>{{producto.Categoria}}</td>
          <td>${{producto.Precio}}</td>
          <td>{{producto.Descripcion}}</td>
          <td><img class="img" src='{{ "https://api2.buscomida.mx/vendedor/productos/" + producto.Id }}' alt=""></td>
          <td><input type="checkbox"  (click)="cambiarEstado(producto)" [checked]="producto.EnUso"></td>
          <td><i class="fa-solid fa-trash" id="trash" (click)="eliminar(producto.Id)"></i>
          <i class="fa-solid fa-pen-to-square" id="edit"></i>
          </td>
      </tr>
          <!--<tr>
              <td>1</td>
              <td>taco</td>
              <td>Carne</td>
              <td>$8000</td>
              <td>Pastor</td>
              <td><img class="img" src="" alt=""></td>
              <td><input type="checkbox"></td>
              <td><a href="">Eliminar</a> |
                <a href="">Actualizar</a></td>
          </tr>
          <tr>
            <td>2</td>
            <td>taco</td>
            <td>Carne</td>
            <td>$8000</td>
            <td>Pastor</td>
            <td><img class="img" src="" alt=""></td>
            <td><input type="checkbox"></td>
            <td><a href="">Eliminar</a> |
              <a href="">Actualizar</a></td>
          </tr>
          <tr>
            <td>3</td>
            <td>taco</td>
            <td>Carne</td>
            <td>$8000</td>
            <td>Pastor</td>
            <td><img class="img" src="" alt=""></td>
            <td><input type="checkbox"></td>
            <td><a href="">Eliminar</a> |
              <a href="">Actualizar</a></td>
          </tr>-->
      </tbody>
  </table>
</div>
<pagination-controls  class="my-pagination" (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
<app-footer></app-footer>
