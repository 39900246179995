<footer>
  <div class="partFooter">
      <img src="assets/img/1.png" alt="">
  </div>
  <div class="partFooter">
      <h4>Servicios</h4>
      <a href="#">Services 1</a>
      <a href="#">Services 2</a>
      <a href="#">Services 3</a>
  </div>
  <div class="partFooter">
      <h4>Acerca de</h4>
      <a href="#">About 1</a>
      <a href="#">About 2</a>
      <a href="#">About 3</a>
  </div>
  <div class="partFooter">
      <h4>Redes sociales</h4>
      <div class="social-media">
              <i class="fab fa-facebook-f"></i>
              <i class="fab fa-twitter"></i>
              <i class="fab fa-instagram"></i>
              <i class="fab fa-youtube"></i>
      </div>
  </div>
</footer>