<app-header></app-header>
<div class="contenedor">
    <section class="contenido-header">
        <section class="textos-header">
            <h1>Bienvenido al modulo de Vendedores</h1>
            <p>Gracias por confiar en nuestro sitio, esperamos y tu estancia aqui sea agradable.</p>
            <a class="btn-detalle">Ver mas</a>
        </section>
        <img src="assets/img/monitor-illustration_2.png" alt="">
    </section>
</div>
<app-card-products></app-card-products>
<app-footer></app-footer>