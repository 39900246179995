import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent {
  constructor(private formService:UsuarioService, private router: Router, private toastr: ToastrService) { }
  @Input() formObj:string ="";
  recuperar() {
    console.info("Form object", this.formObj);
    let s =this.formService.recuperar(this.formObj);
    s.then(resultado =>{
      console.warn("Resultado:", resultado);
      if (resultado >0){
        this.router.navigate(['/login']);
        this.toastr.success('Contraseña enviada correctamente, revise su correo', 'Contraseña enviada')

      }
      else{
        this.toastr.error('el usuario no existe','Error')
      }
    });
  }
}
