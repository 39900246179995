export class Producto {
    Id:number =0;
    Nombre:string ="";
    Descripcion:string ="";
    Categoria:string ="";
    Precio:Number =0;
    Archivo:File|null =null;
    IdUsuario:number =0;
    EnUso:boolean = false;
}
