<app-header></app-header>
<div class="con">
  <div class="container">
    <header>Registro de productos</header>
    <form action="#">
      <div class="form first">
        <div class="details personal">
          <div class="fields">
            <div class="input-field">
              <label>Nombre del producto</label>
              <input type="text" name="formObj.Nombre" [(ngModel)]="formObj.Nombre" placeholder="Nombre del producto" required />
            </div>

            <div class="input-field">
              <label>Descripción</label>
              <textarea name="formObj.Descripción" [(ngModel)]="formObj.Descripcion" required> </textarea>
            </div>

            <div class="input-field">
              <label>Categoria</label>
              <input type="textarea" name="formObj.Categoria" [(ngModel)]="formObj.Categoria" placeholder="Categoría" required />
            </div>

              <div class="file">
                <label for="file">
                  <i class="fa-solid fa-file-circle-plus"></i> &nbsp; Imagen
                </label>
                <input  type="file" id="file" required (change)="archivoSeleccionado($event.target)"  accept="image/*"/>
              </div>

            <div class="input-field">
              <label>Precio</label>
              <input type="number" name="formObj.Precio" [(ngModel)]="formObj.Precio" placeholder="Precio" required />
            </div>
          </div>
          <button class="nextBtn" (click)="crear()">
            <span class="btnText">Registrar</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>