<app-header></app-header>
<div class="container">
  <table class="content-table">
    <thead>
      <tr>
        <th>Articilo</th>
        <th>Descripcion</th>
        <th>Precio</th>
        <th>Cantidad</th>
        <th>Eliminar</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Tacos <img src="assets/img/untitled-copy.jpg" alt=""></td>
        <td>Pastor</td>
        <td>50</td>
        <td>5</td>
        <td>
          <a class="link_delete" href=""><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>
      <tr>
        <td>Tacos <img src="assets/img/untitled-copy.jpg" alt=""></td>
        <td>Pastor</td>
        <td>50</td>
        <td>5</td>
        <td>
          <a class="link_delete" href=""><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>
      <tr>
        <td>Tacos <img src="assets/img/untitled-copy.jpg" alt=""></td>
        <td>Pastor</td>
        <td>50</td>
        <td>5</td>
        <td>
          <a class="link_delete" href=""><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>
    </tbody>
    <a class="btn-shopping">Comprar <i class="fa-solid fa-bag-shopping"></i></a>
    <p>Total:$150</p>
  </table>
</div>
<app-footer></app-footer>