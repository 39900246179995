import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Sesion } from '../models/sesion.model';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  constructor(private http: HttpClient) { }
  crear (frmObj:Sesion):Promise<number>{
    let req = this.http.post<{Resultado:number}>("https://api2.buscomida.mx/vendedor/sesion/Nueva", frmObj, { observe: 'response' });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }
}
