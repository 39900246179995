import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"

@Component({
  selector: 'app-base',
  template: `
    <p>
      base works!
    </p>
  `,
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    console.warn("excuting base init");
    let idUsuario = localStorage.getItem("IdUsuario");
    console.warn("Lodaing data for user:",idUsuario, "on "+ this.router.url);
    let cUrl = this.router.url;
    if (idUsuario !=null && (cUrl == "/login" || cUrl == "/registro"))
      this.router.navigate(['/inicio']);
  }
}
