import { Component, OnInit, Input } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import {Router} from "@angular/router"
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valApellidos, valcorreo, valNombre, valPassword, valTelefono, valUsuario } from '../validaciones/validaciones';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  @Input() formObj: Usuario = new Usuario();
  constructor(private productService: UsuarioService, private router: Router, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  crear() {
    console.info("Form object", this.formObj);
    let s =this.productService.crear(this.formObj);
    s.then(resultado =>{
      console.warn("Resultado:", resultado);
      if ( resultado >0 ){
        this.router.navigate(['/login']);
        this.toastr.success('Te has registrado correctamente', '¡Felicidades!')
      }else{
        this.toastr.error('¡Usuario o correo ya registrado!', 'Error')
      }
    });
  }
  Formulario: FormGroup = this.fb.group({
    nombre: ['', [Validators.required, Validators.pattern(valNombre)]],
    apellidos: ['', [Validators.required, Validators.pattern(valApellidos)]],
    telefono: ['', [Validators.required, Validators.pattern(valTelefono)]],
    usuario: ['', [Validators.required, Validators.pattern(valUsuario)]],
    password: ['', [Validators.required, Validators.pattern(valPassword)]],
    correo: ['', [Validators.required, Validators.pattern(valcorreo)]]
  })
  campoNoValido(campo:string){
    return this.Formulario.get(campo)?.invalid
    && this.Formulario.get(campo)?.touched;
  }
  submitformulario(){
    console.warn("¿Existe usuario?...",this.Formulario.valid)
    if(this.Formulario.valid){
      console.log("enviando.....")
      this.crear()
    }
    this.Formulario.markAllAsTouched();
  }
}
